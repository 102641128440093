import React, { useEffect, useState } from "react";
import EmailModal from "./EmailModal/EmailModal.jsx";
import ResetChat from "./Svg/ResetChat.jsx";
import SaveChat from "./Svg/SaveChat.jsx";
import ExpandChat from "./Svg/ExpandChat.jsx";
import CollapseChat from "./Svg/CollapseChat.jsx";
import HideChat from "./Svg/HideChat.jsx";
import ShowChat from "./Svg/ShowChat.jsx";
import useTheme from "../contexts/darkThemeContext.js";
import DarkMode from "./Svg/DarkMode.jsx";
import LightMode from "./Svg/LightMode.jsx";
import Loader from "./Loader/Loader";
import { CONSTANTS } from "../Constants/Constants.js";
import useDiffLang from "../contexts/langContext.js";

export const ChatHeader = ({
  threadInfos,
  setShowChat,
  showChat,
  setIsFullScreen,
  isFullScreen,
  widgetInfo,
  resetChat,
  enableScroll,
  disableScroll,
  removeTooltip,
  messageCount = 1,
  showEmailBox,
  setShowEmailBox,
}) => {
  const [imgSrc, setImgSrc] = useState("");

  const { isDarkTheme, toggleTheme } = useTheme();
  const { defaultLang } = useDiffLang();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [widgetMode, setWidgetMode] = useState("avatar");

  useEffect(() => {
    setTimeout(() => {
      const { imgSrc, avatarUrl, widgetMode } = window?.lastbotSettings;
      console.log("settings", window?.lastbotSettings);
      console.log("avatarUrl", avatarUrl);
      setImgSrc(imgSrc);
      setAvatarUrl(avatarUrl);
      setWidgetMode(widgetMode || "full");
    }, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const { imgSrc } = window?.lastbotSettings;
      setImgSrc(imgSrc);
    }, 0);
  }, []);

  useEffect(() => {
    if (!showChat) {
      enableScroll();
    }
    if (showChat) {
      disableScroll();
    }
  }, [showChat]);

  const openEmailPopup = (e) => {
    e.stopPropagation();
    removeTooltip();
    setShowEmailBox(true);
  };

  const closeEmailBox = () => {
    setShowEmailBox(false);
  };

  const enableDarkMode = (e) => {
    e.stopPropagation();
    toggleTheme();
  };

  return (
    <>
      {showEmailBox && (
        <EmailModal threadInfos={threadInfos} closeEmailBox={closeEmailBox} />
      )}

      <div
        className={
          showChat || widgetMode != "avatar" ? "lcb_chat-header-wrapper" : ""
        }
        onClick={() => {
          setShowChat((prev) => !prev);
          removeTooltip();
        }}
      >
        {!showChat && widgetMode == "avatar" && (
          <>
            <img src={avatarUrl} alt="Chat Bot" />
            <div className="message-count">{messageCount}</div>{" "}
            {/* Add this line */}
          </>
        )}
        {(showChat || widgetMode != "avatar") && (
          <div className="lcb_headertext_wrapper">
            <div className="lcb_user-avatar">
              {avatarUrl ? (
                <img src={avatarUrl} alt="user-avatar" width="50px" />
              ) : (
                widgetInfo?.initials
              )}
            </div>
            <div className="lcb_bot-name">
              {imgSrc ? (
                <img
                  className="lcb_company-logo-img"
                  src={imgSrc}
                  alt="company-logo-img"
                />
              ) : (
                <span className="lcb_headertext">
                  {widgetInfo?.name ? widgetInfo.name : <Loader />}
                </span>
              )}
              <span className="lcb_bot-fontstyle">
                {CONSTANTS.HEADER.botLable}
              </span>
            </div>
          </div>
        )}
        <div className="lcb_chat-header-btn-wrapper">
          {showChat && (
            <span
              data-tooltip-id="lcb_my-tooltip"
              data-tooltip-content={defaultLang.refresh_btn}
              onClick={(e) => {
                e.stopPropagation();
                removeTooltip();
                resetChat();
              }}
            >
              <ResetChat />
            </span>
          )}

          {showChat && (
            <span
              data-tooltip-id="lcb_my-tooltip"
              data-tooltip-content={defaultLang.save_btn}
              onClick={openEmailPopup}
            >
              <SaveChat />
            </span>
          )}

          {showChat && (
            <span
              data-tooltip-id="lcb_my-tooltip"
              data-tooltip-content={defaultLang.switch_btn}
              onClick={(e) => {
                enableDarkMode(e);
              }}
            >
              {isDarkTheme ? <LightMode /> : <DarkMode />}
            </span>
          )}

          {window.innerWidth > 769 && showChat && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                removeTooltip();
                setIsFullScreen((prev) => !prev);
              }}
            >
              {!isFullScreen ? (
                <span
                  data-tooltip-id="lcb_my-tooltip"
                  data-tooltip-content={defaultLang.fs_open}
                >
                  <CollapseChat />
                </span>
              ) : (
                <span
                  data-tooltip-id="lcb_my-tooltip"
                  data-tooltip-content={defaultLang.fs_close}
                >
                  <ExpandChat />
                </span>
              )}
            </span>
          )}

          <span>
            {showChat ? (
              <span
                data-tooltip-id="lcb_my-tooltip"
                data-tooltip-content={defaultLang.widget_close}
              >
                <HideChat />
              </span>
            ) : widgetMode == "avatar" ? (
              ""
            ) : (
              <span
                data-tooltip-id="lcb_my-tooltip"
                data-tooltip-content={defaultLang.widget_open}
              >
                <ShowChat />
              </span>
            )}
          </span>
        </div>
      </div>
    </>
  );
};
