import { useState, useEffect } from "react";
import useDiffLang from "../contexts/langContext";

import StarIcon from "./Svg/StarIcon";

function ChatReview({
  submitted,
  setSubmited,
  reviewRate,
  setReviewRate,
  setResetReviewedChat,
}) {
  const [hoveredRating, setHoveredRating] = useState(0);

  const { defaultLang } = useDiffLang();

  const handleMouseEnter = (rating) => {
    setHoveredRating(rating);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  const handleClick = (event, rating) => {
    event.stopPropagation();
    setReviewRate(rating);
    setSubmited(true);
  };

  return (
    <div className="lcb_review-container">
      <div>
        <h3 className="lcb_review-title">{defaultLang.review_title}</h3>
        <div className="lcb_review-rate-container">
          <div className="lcb_review-rate">
            {[1, 2, 3, 4, 5].map((id) => (
              <div
                key={id}
                className="lcb_review-stars"
                data-rate={id}
                style={{
                  color: `${
                    id <= (hoveredRating || reviewRate) ? "#e5e572" : "#b2b2b2"
                  }`,
                }}
                onMouseEnter={() => handleMouseEnter(id)}
                onMouseLeave={handleMouseLeave}
                onClick={(event) => handleClick(event, id)}
              >
                <span style={{ pointerEvents: "none" }}>
                  <StarIcon />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {submitted ? (
        <div className="lcb_review-feedback">
          <p>{defaultLang.end_saluation}</p>
          <div>
            <button
              className="lcb_review-reset"
              onClick={() => setResetReviewedChat(true)}
            >
              {defaultLang.start_new_conversation}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ChatReview;
